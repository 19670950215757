<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="irrigationTypes"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            IrrigationTypes
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            width="800"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Irrigation Type
              </v-btn>
            </template>
            <IrrigationTypeForm
              @canceled="handleCanceledIrrigationType"
              @created="handleCreatedIrrigationType"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/irrigationTypes/${item._id}`"
          @delete="deleteIrrigationType(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'
import { irrigationTypeFields } from './constants'
import IrrigationTypeForm from './IrrigationTypeForm'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminIrrigationType',
  components: {
    IrrigationTypeForm,
    EditAndDeleteButtons
  },
  data () {
    const irrigationTypeColumns = irrigationTypeFields.map(({ name, label, suffix }) => {
      const text = label + (suffix ? ` (${suffix})` : '')
      return {
        text,
        value: name
      }
    })
    const headers = [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'CPD - IrrigationType Id',
        value: 'cpdIrrigationTypeId'
      },
      ...irrigationTypeColumns,
      {
        text: 'Notes',
        value: 'notes'
      },
      {
        text: 'Citations',
        value: 'citations'
      },
      {
        value: 'actions',
        sortable: false
      }
    ]
    return {
      headers,
      irrigationTypes: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchIrrigationTypes()
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchIrrigationTypes () {
      const { data: irrigationTypes } = await this.$axios.get('/irrigation_types')
      this.irrigationTypes = irrigationTypes
    },
    async deleteIrrigationType (id) {
      try {
        await this.$axios.delete(`/irrigation_types/${id}`)
        await this.fetchIrrigationTypes()
      } catch (err) {
        await this.showSnackbar({ color: 'error', message: 'Unable to delete irrigationType. Please try again.' })
        throw err
      }
    },
    handleCanceledIrrigationType () {
      this.showForm = false
    },
    handleCreatedIrrigationType () {
      this.showForm = false
      this.fetchIrrigationTypes()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
